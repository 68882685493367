import React from 'react';

import CCTVPolicies from 'components/docs/CCTVPolicies';
import PageTitleBar from 'components/misc/PageTitleBar';

const CCTVPoliciesPage = () => (
  <div>
    <PageTitleBar
      title="영상정보처리기기운영 · 관리방침"
      titleLangKor
      backgroundImage={require('../assets/images/banner/agreement.jpg')}
    />
    <CCTVPolicies />
  </div>
);

export default CCTVPoliciesPage;
